<template>
    <div>
        <el-dialog
            title="结算规则"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                    <el-form-item prop="settlement_id" label="结算规则ID">
                        <el-select v-model="form.settlement_id" clearable @change="settlement_idChange">
                            <el-option v-for="item in settlement_idDic"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="tips">
                            <span>
                                1.请选择二级商户的结算规则ID，需匹配电商平台开通工具箱选择的费率档位，详细参见
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/220228qEfuAz220228bMFji6.html')">电商二级商户结算规则对照表</span>
                            </span>
                            <span>2.若电商平台未传入，将默认选择0.6%费率对应的结算规则id。</span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="qualification_type" label="所属行业">
                        <el-input maxlength="200" v-model="form.qualification_type" placeholder="请填写所属行业" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>
                                1.请填写二级商户所属的行业名称，映射特殊资质要求，详细参见
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/220228qEfuAz220228bMFji6.html')">电商二级商户结算规则对照表</span>
                            </span>
                            <span>2.若电商平台未传入，将默认填写无需特殊资质的行业名称。</span>
                            <span>3.示例值：零售批发/生活娱乐/其他。</span>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                form: {
                    settlement_id: '',//结算规则ID	
                    qualification_type: '',//所属行业	
                },
                rules: {

                },
                settlement_idDic: [],
                qualification_typeDic: [],
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
            },
        },
        created () {
            this.$getDic('settlement_id','select').then(res=>{ this.settlement_idDic = res; })
            this.$getDic('qualification_type','select').then(res=>{ this.qualification_typeDic = res; })
        },
        methods:{
            settlement_idChange () {

            },
            qualification_typeChange () {

            },
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$message.success('操作成功')
                        let reqData = {
                            ...this.form
                        }
                        this.$emit('change',reqData);
                        this.dialogVisible = false
                    }
                })
                
            },
            windowOpen (url) {
                window.open(url)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>