<template>
    <div>
        <el-dialog
            title="经营者/法人其他类型证件信息"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                    <el-form-item prop="id_doc_copyImg" label="证件正面照片">
                        <le-upload-img-wx notnull ref="id_doc_copyImg" label="" :limit="1" :imgList.sync="form.id_doc_copyImg" :mediaList.sync="form.id_doc_copy"></le-upload-img-wx>
                        <div class="tips">
                            <span>1.照片应正面拍摄、清晰、四角完整、无反光或遮挡；不得翻拍、截图、镜像、PS。</span>
                            <span>
                                2.请上传彩色照片or彩色扫描件，复印件需加盖公章鲜章，可添加“微信支付”相关水印（如微信支付认证），见
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/230615MjMBRf230615NNn2yY.html')">【指引文档】</span>
                            </span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="id_doc_copy_backImg" label="证件反面照片">
                        <le-upload-img-wx notnull ref="id_doc_copy_backImg" label="" :limit="1" :imgList.sync="form.id_doc_copy_backImg" :mediaList.sync="form.id_doc_copy_back"></le-upload-img-wx>
                        <div class="tips">
                            <span>1.照片应正面拍摄、清晰、四角完整、无反光或遮挡；不得翻拍、截图、镜像、PS。</span>
                            <span>
                                2.请上传彩色照片or彩色扫描件，复印件需加盖公章鲜章，可添加“微信支付”相关水印（如微信支付认证），见
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/230615MjMBRf230615NNn2yY.html')">【指引文档】</span>
                            </span>
                        </div>
                    </el-form-item>
                    <el-form-item label="证件姓名" prop="id_doc_name">
                        <el-input maxlength="100" v-model="form.id_doc_name" placeholder="请输入证件姓名" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.前后不能有空格、制表符、换行符，不能仅含数字、特殊字符，仅能填写数字、英文字母、汉字及特殊字符。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="证件号码" prop="id_doc_number">
                        <el-input maxlength="100" v-model="form.id_doc_number" placeholder="请输入证件号码" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.前后不能有空格、制表符、换行符，不能仅含数字、特殊字符，仅能填写数字、英文字母、汉字及特殊字符。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="证件居住地址" prop="id_doc_address">
                        <el-input maxlength="128" v-model="form.id_doc_address" placeholder="请输入证件居住地址" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.主体类型为企业时，需要填写。其他主体类型，无需上传。</span>
                            <span>2.请按照证件居住地址填写。</span>
                            <span>3.前后不能有空格、制表符、换行符，不能仅含数字、特殊字符，仅能填写数字、英文字母、汉字及特殊字符。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="证件有效期开始时间" prop="doc_period_begin">
                        <el-date-picker
                            v-model="form.doc_period_begin"
                            type="date"
                            :value-format="'yyyy-MM-dd'"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="证件有效期结束时间" prop="doc_period_end">
                        <el-date-picker
                            :disabled="business_timeType"
                            v-model="form.doc_period_end"
                            type="date"
                            :value-format="'yyyy-MM-dd'"
                            placeholder="选择日期">
                        </el-date-picker>
                        <el-checkbox class="a-mlr-12" v-model="business_timeType" @change='business_timeTypeChange'>长期</el-checkbox>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
import LeUploadImgWx from '../../components/form/le-upload-img-wx.vue';
    export default {
        components: {
            LeUploadImgWx
        },
        data() {
            var checkId_doc_address = (rule, value, callback) => {
                if (this.organization_type == '2') {
                    if(!this.form.id_doc_address) {
                        callback(new Error('请填写身份证居住地址'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkId_doc_copy_backImg = (rule, value, callback) => {
                if (this.id_doc_type != 'IDENTIFICATION_TYPE_OVERSEA_PASSPORT') {
                    if(!this.form.id_doc_copy_backImg) {
                        callback(new Error('请上传证件反面照片'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkDoc_period_end = (rule, value, callback) => {
                if (this.business_timeType) {
                    callback();
                } else {
                    if(!this.form.doc_period_end) {
                        callback(new Error('请选择证件有效期结束时间'));
                    }else{
                        callback();
                    }
                }
            };
            return {
                dialogVisible: false,
                form: {
                    id_doc_copyImg: [],//证件正面照片		
                    id_doc_copy: [],//证件正面照片	MediaID
                    id_doc_copy_backImg: [],//证件反面照片
                    id_doc_copy_back: [],//证件反面照片 MediaID
                    id_doc_name: '',//证件姓名	
                    id_doc_number: '',//证件号码	
                    id_doc_address: '',//证件居住地址	
                    doc_period_begin: '',//证件有效期开始时间
                    doc_period_end: '',//证件有效期结束时间		
                },
                rules: {
                    id_doc_copyImg: [{required: true, message:'请上传证件正面照片', trigger: 'change'}],//证件正面照片		
                    id_doc_copy_backImg: [{ validator: checkId_doc_copy_backImg, trigger: 'change' }],//证件反面照片
                    id_doc_name: [{required: true, message:'请填写证件姓名', trigger: 'blur'}],//证件姓名	
                    id_doc_number: [{required: true, message:'请填写证件号码', trigger: 'blur'}],//证件号码	
                    id_doc_address: [{ validator: checkId_doc_address, trigger: 'blur' }],//证件居住地址	
                    doc_period_begin: [{required: true, message:'请选择证件有效期开始时间', trigger: 'change'}],//证件有效期开始时间
                    doc_period_end: [{ validator: checkDoc_period_end, trigger: 'change'}],//证件有效期结束时间		
                },
                business_timeType: false
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
                this.$nextTick(()=>{
                    this.$refs['id_doc_copyImg'].initFileList()
                    this.$refs['id_doc_copy_backImg'].initFileList()
                })
            },
        },
        props: ['organization_type','id_doc_type'],
        created () {
            // this.$getDic('purchasePayType').then(res=>{ this.stateDic = res; })
        },
        methods:{
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$message.success('操作成功')
                        if(this.business_timeType){
                            this.form.doc_period_end = '长期'
                        }
                        let reqData = {
                            ...this.form
                        }
                        this.$emit('change',reqData);
                        this.dialogVisible = false
                    }
                })
                
            },
            business_timeTypeChange () {
                this.form.doc_period_end = ''
            },
            windowOpen (url) {
                window.open(url)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>