import { render, staticRenderFns } from "./companyUpgrade-edit.vue?vue&type=template&id=0ec49722&scoped=true&"
import script from "./companyUpgrade-edit.vue?vue&type=script&lang=js&"
export * from "./companyUpgrade-edit.vue?vue&type=script&lang=js&"
import style0 from "./companyUpgrade-edit.vue?vue&type=style&index=0&id=0ec49722&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec49722",
  null
  
)

export default component.exports