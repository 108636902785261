<template>
    <div class="app-body">
        <div class="a-flex-rsbc">
            <bread-crumb></bread-crumb>
        </div>
        <el-card class="editWarningUser">
            <el-form :model="form" ref="form" :rules="rules" label-width="150px" label-position="left" :disabled="editType == 'info'">
                <el-form-item label="进件类型" prop="servicePlatform">
                    <el-select v-model="form.servicePlatform" :clearable='false' @change="servicePlatformChange">
                        <el-option v-for="item in servicePlatformDic"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商户" prop="companyId" label-width="130px">
                    <le-company-solo-select style="padding: 0;width: 400px" label="" placeholder="请选择（可输入搜索）" v-model="form.companyId"></le-company-solo-select>
                </el-form-item>
                <div v-if="form.servicePlatform == 1">
                    <el-form-item label="主体类型" prop="organization_type">
                        <el-select v-model="form.organization_type" clearable @change="organization_typeChange">
                            <el-option v-for="item in organization_typeDic"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="tips">
                            <span>1.非小微的主体类型需与营业执照/登记证书上一致</span>
                            <span class="a-plr-18">(1) 小微商户，指无营业执照的个人商家。</span>
                            <span class="a-plr-18">(2) 个人卖家，指无营业执照，已持续从事电子商务经营活动满6个月，且期间经营收入累计超过20万元的个人商家。（若选择该主体，请在“补充说明”填写相关描述）。</span>
                            <span class="a-plr-18">(3) 个体工商户，营业执照上的主体类型一般为个体户、个体工商户、个体经营。</span>
                            <span class="a-plr-18">(4) 企业，营业执照上的主体类型一般为有限公司、有限责任公司。</span>
                            <span class="a-plr-18">(5) 事业单位，包括国内各类事业单位，如：医疗、教育、学校等单位。</span>
                            <span class="a-plr-18">(6) 政府机关，包括各级、各类政府机关，如机关党委、税务、民政、人社、工商、商务、市监等。</span>
                            <span class="a-plr-18">(7) 社会组织，包括社会团体、民办非企业、基金会、基层群众性自治组织、农村集体经济组织等组织。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="是否金融机构" prop="finance_institution">
                        <el-radio-group v-model="form.finance_institution">
                            <el-radio :label="item.value" v-for="(item,index) in yesOrNoDic" :key="index">{{ item.text }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="营业执照/登记证书信息" prop="business_license_info" v-if="form.organization_type != 2401 && form.organization_type != 2500">
                        <el-button icon="el-icon-plus" size="mini" type="primary" v-if="editType != 'info'" @click="addBusinessLicenseInfo">{{ form.business_license_info?'修改':'添加' }}</el-button>
                        <el-card shadow="hover" class="a-mt-20 " :body-style="{ padding: '10px' }" v-if="form.business_license_info">
                            <div class="a-flex-rfsfs a-flex-wrap">
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证书类型</span>
                                    <span class="content a-c-master">{{ form.business_license_info.cert_type | initDic(cert_typeDic) }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">营业执照扫描件</span>
                                    <div class="content">
                                        <el-image class="infoImg" 
                                            v-for="(item,index) in form.business_license_info.business_license_copyImg"
                                            :key="index"
                                            :preview-src-list="form.business_license_info.business_license_copyImg" 
                                            :src="item" 
                                            fit="fill" 
                                            :lazy="true"></el-image>
                                    </div>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">营业执照注册号</span>
                                    <span class="content a-c-master">{{ form.business_license_info.business_license_number || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">商户名称</span>
                                    <span class="content a-c-master">{{ form.business_license_info.merchant_name || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">经营者/法定代表人姓名</span>
                                    <span class="content a-c-master">{{ form.business_license_info.legal_person || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">注册地址</span>
                                    <span class="content a-c-master">{{ form.business_license_info.company_address || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">营业期限</span>
                                    <span class="content a-c-master a-flex-rfsfs">
                                        {{ form.business_license_info.business_time[0] || '-' }}
                                        <span class="a-plr-18">至</span>
                                        {{ form.business_license_info.business_time[1] || '-' }}
                                    </span>
                                </div>
                            </div>
                        </el-card>
                        <div class="tips">
                            <span>1.主体为“个体工商户/企业”时，请上传营业执照。</span>
                            <span>2.主体为“政府机关/事业单位/社会组织”时，请上传登记证书。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="金融机构许可证信息" prop="finance_institution_info" v-if="form.finance_institution == '1'">
                        <el-button icon="el-icon-plus" size="mini" type="primary" v-if="editType != 'info'" @click="addFinanceInstitutionInfo">{{ form.finance_institution_info?'修改':'添加' }}</el-button>
                        <el-card shadow="hover" class="a-mt-20 " :body-style="{ padding: '10px' }" v-if="form.finance_institution_info">
                            <div class="a-flex-rfsfs a-flex-wrap">
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证书类型</span>
                                    <span class="content a-c-master">{{ form.finance_institution_info.finance_type | initDic(finance_typeDic) }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">金融机构许可证</span>
                                    <div class="content">
                                        <el-image 
                                            class="infoImg" 
                                            v-for="(item,index) in form.finance_institution_info.finance_license_picsImg"
                                            :key="index"
                                            :preview-src-list="form.finance_institution_info.finance_license_picsImg" 
                                            :src="item" 
                                            fit="fill" :lazy="true"></el-image>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                        
                    </el-form-item>
                    <el-form-item label="证件持有人类型" prop="id_holder_type" v-if="form.organization_type == 3 || form.organization_type == 2502">
                        <el-select v-model="form.id_holder_type" clearable @change="id_holder_typeChange">
                            <el-option v-for="item in id_holder_typeDic"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="tips">
                            <span>1.主体类型为政府机关/事业单位时选传：</span>
                            <span class="a-plr-18">(1) 若上传的是法人证件，则不需要上传该字段。</span>
                            <span class="a-plr-18">(2) 若因特殊情况，无法提供法人证件时，可上传经办人。 （经办人：经商户授权办理微信支付业务的人员，授权范围包括但不限于签约，入驻过程需完成账户验证）。</span>
                            <span>2.主体类型为企业/个体户/社会组织时，默认为经营者/法人，不需要上传该字段。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="经营者/法人证件类型" prop="id_doc_type">
                        <el-select v-model="form.id_doc_type" @change="id_doc_typeChange">
                            <el-option v-for="item in id_doc_typeDic"
                                :key="item.value"
                                :label="item.label"
                                :disabled="form.organization_type == 2502 && item.value != 'IDENTIFICATION_TYPE_MAINLAND_IDCARD'"
                                :value="item.value">
                            </el-option>
                        </el-select> 
                        <div class="tips">
                            <span>1.当证件持有人类型为经营者/法人时，需要填写。其他情况，无需上传。</span>
                            <span>2.主体为“小微/个人卖家”，可选择：身份证。</span>
                            <span>3.主体为“个体户/企业/事业单位/社会组织”：可选择任一证件类型，主体为“政府机关”仅支持中国大陆居民-身份证类型。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="法定代表人说明函" prop="authorize_letter_copyImg">
                        <le-upload-img-wx :readonly="editType == 'info'" notnull ref="icon" label="" :limit="1" :imgList.sync="form.authorize_letter_copyImg" :mediaList.sync="form.authorize_letter_copy"></le-upload-img-wx>
                        <div class="tips">
                            <div class="a-flex-rfsc">
                                <span>1.若因特殊情况，无法提供法定代表人证件时，请参照</span>
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/220127aUzAju220127UfiuQr.html')">示例图</span>
                                <!-- <el-link type="primary" href=""></el-link> -->
                                <span>打印法定代表人说明函，全部信息需打印，不支持手写商户信息，并加盖公章。</span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="经营者/法人身份证信息" prop="id_card_info">
                        <el-button icon="el-icon-plus" size="mini" type="primary" v-if="editType != 'info'" @click="addIdCardInfo">{{ form.id_card_info?'修改':'添加' }}</el-button>
                        <el-card shadow="hover" class="a-mt-20 " :body-style="{ padding: '10px' }" v-if="form.id_card_info">
                            <div class="a-flex-rfsfs a-flex-wrap">
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">身份证姓名</span>
                                    <span class="content a-c-master">{{ form.id_card_info.id_card_name || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">身份证号码</span>
                                    <span class="content a-c-master">{{ form.id_card_info.id_card_number || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">身份证居住地址</span>
                                    <span class="content a-c-master">{{ form.id_card_info.id_card_address || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">身份证开始时间</span>
                                    <span class="content a-c-master">{{ form.id_card_info.id_card_valid_time_begin || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">身份证结束时间</span>
                                    <span class="content a-c-master">{{ form.id_card_info.id_card_valid_time || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">身份证人像面照片</span>
                                    <div class="content">
                                        <el-image
                                            class="infoImg" 
                                            v-for="(item,index) in form.id_card_info.id_card_copyImg"
                                            :key="index"
                                            :preview-src-list="form.id_card_info.id_card_copyImg" 
                                            :src="item" fit="fill" :lazy="true"></el-image>
                                    </div>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">身份证国徽面照片</span>
                                    <div class="content">
                                        <el-image 
                                            class="infoImg" 
                                            v-for="(item,index) in form.id_card_info.id_card_nationalImg"
                                            :key="index"
                                            :preview-src-list="form.id_card_info.id_card_nationalImg" 
                                            :src="item" fit="fill" :lazy="true"></el-image>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-form-item>
                    <el-form-item label="经营者/法人其他类型证件信息" prop="id_doc_info">
                        <el-button icon="el-icon-plus" size="mini" type="primary" v-if="editType != 'info'" @click="addIdDocInfo">{{ form.id_doc_info?'修改':'添加' }}</el-button>
                        <el-card shadow="hover" class="a-mt-20 " :body-style="{ padding: '10px' }" v-if="form.id_doc_info">
                            <div class="a-flex-rfsfs a-flex-wrap">
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件姓名</span>
                                    <span class="content a-c-master">{{ form.id_doc_info.id_doc_name || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件号码</span>
                                    <span class="content a-c-master">{{ form.id_doc_info.id_doc_number || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件居住地址</span>
                                    <span class="content a-c-master">{{ form.id_doc_info.id_doc_address || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件有效期开始时间</span>
                                    <span class="content a-c-master">{{ form.id_doc_info.doc_period_begin || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件有效期结束时间</span>
                                    <span class="content a-c-master">{{ form.id_doc_info.doc_period_end || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件正面照片</span>
                                    <div class="content">
                                        <el-image 
                                            class="infoImg" 
                                            v-for="(item,index) in form.id_doc_info.id_doc_copyImg"
                                            :key="index"
                                            :preview-src-list="form.id_doc_info.id_doc_copyImg" 
                                            :src="item" fit="fill" :lazy="true"></el-image>
                                    </div>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件反面照片</span>
                                    <div class="content">
                                        <el-image 
                                            class="infoImg" 
                                            v-for="(item,index) in form.id_doc_info.id_doc_copy_backImg"
                                            :key="index"
                                            :preview-src-list="form.id_doc_info.id_doc_copy_backImg" 
                                            :src="item" fit="fill" :lazy="true"></el-image>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-form-item>
                    <el-form-item label="经营者/法人是否为受益人" prop="owner" v-if="form.organization_type == 2">
                        <el-radio-group v-model="form.owner" @change="ownerChange">
                            <el-radio :label="item.value" v-for="(item,index) in yesOrNoDic" :key="index">{{ item.text }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="最终受益人信息列表" prop="ubo_info_list" v-if="form.organization_type == 2">
                        <el-button icon="el-icon-plus" size="mini" type="primary" v-if="editType != 'info'" @click="addUboInfoList">添加</el-button>
                        <el-card shadow="hover" class="a-mt-20 ubo_info_list_card" :body-style="{ padding: '10px' }" v-for="(item,index) in form.ubo_info_list" :key="index">
                            <div class="a-flex-rfsfs a-flex-wrap">
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件类型</span>
                                    <span class="content a-c-master">{{ item.ubo_id_doc_type | initDic(ubo_id_doc_typeDic) }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件正面照片</span>
                                    <div class="content">
                                        <el-image 
                                            class="infoImg" 
                                            v-for="(ite,index) in item.ubo_id_doc_copyImg"
                                            :key="index"
                                            :preview-src-list="item.ubo_id_doc_copyImg" 
                                            :src="ite" fit="fill" :lazy="true"></el-image>
                                    </div>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件反面照片</span>
                                    <div class="content">
                                        <el-image 
                                            class="infoImg" 
                                            v-for="(ite,index) in item.ubo_id_doc_copy_backImg"
                                            :key="index"
                                            :preview-src-list="item.ubo_id_doc_copy_backImg" 
                                            :src="ite" fit="fill" :lazy="true"></el-image>
                                    </div>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件姓名</span>
                                    <span class="content a-c-master">{{ item.ubo_id_doc_name || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件号码</span>
                                    <span class="content a-c-master">{{ item.ubo_id_doc_number || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件居住地址</span>
                                    <span class="content a-c-master">{{ item.ubo_id_doc_address || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件有效期开始时间</span>
                                    <span class="content a-c-master">{{ item.ubo_id_doc_period_begin || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">证件有效期结束时间</span>
                                    <span class="content a-c-master">{{ item.ubo_id_doc_period_end || '-' }}</span>
                                </div>
                            </div>
                            <i class="el-icon-close deleteUbo_info_list" @click="deleteUbo_info_item(index)"></i>
                        </el-card>
                        <div class="tips">
                            <span>若经营者/法人不是最终受益所有人，则需提填写受益所有人信息，最多上传4个。</span>
                            <span>若经营者/法人是最终受益所有人之一，可在此填写其他受益所有人信息，最多上传3个。</span>
                            <span>根据国家相关法律法规，需要提供公司受益所有人信息，受益所有人需符合至少以下条件之一：</span>
                            <span>1.直接或者间接拥有超过25%公司股权或者表决权的自然人。</span>
                            <span>2.通过人事、财务等其他方式对公司进行控制的自然人。</span>
                            <span>3.公司的高级管理人员，包括公司的经理、副经理、财务负责人、上市公司董事会秘书和公司章程规定的其他人员。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="结算账户信息" prop="account_info">
                        <el-button icon="el-icon-plus" size="mini" type="primary" v-if="editType != 'info'" @click="addAccountInfo">{{ form.account_info?'修改':'添加' }}</el-button>
                        <el-card shadow="hover" class="a-mt-20 " :body-style="{ padding: '10px' }" v-if="form.account_info">
                            <div class="a-flex-rfsfs a-flex-wrap">
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">账户类型</span>
                                    <span class="content a-c-master">{{ form.account_info.bank_account_type | initDic(bank_account_typeDic) }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户银行</span>
                                    <span class="content a-c-master">{{ form.account_info.account_bank }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户银行别名</span>
                                    <span class="content a-c-master">{{ form.account_info.bank_alias }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户名称</span>
                                    <span class="content a-c-master">{{ form.account_info.account_name || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户银行省市编码</span>
                                    <span class="content a-c-master">{{ form.account_info.bank_address_code || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户银行联行号</span>
                                    <span class="content a-c-master">{{ form.account_info.bank_branch_id || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户银行全称（含支行）</span>
                                    <span class="content a-c-master">{{ form.account_info.bank_name }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">银行账号</span>
                                    <span class="content a-c-master">{{ form.account_info.account_number || '-' }}</span>
                                </div>
                            </div>
                        </el-card>
                        <div class="tips">
                            <span>请填写商家提现收款的银行账户信息</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="超级管理员信息" prop="contact_info">
                        <el-button icon="el-icon-plus" size="mini" type="primary" v-if="editType != 'info'" @click="addContactInfo">{{ form.contact_info?'修改':'添加' }}</el-button>
                        <el-card shadow="hover" class="a-mt-20 " :body-style="{ padding: '10px' }" v-if="form.contact_info">
                            <div class="a-flex-rfsfs a-flex-wrap">
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">超级管理员类型</span>
                                    <span class="content a-c-master">{{ form.contact_info.contact_type | initDic(contact_typeDic) }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">超级管理员姓名</span>
                                    <span class="content a-c-master">{{ form.contact_info.contact_name || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">超级管理员证件类型</span>
                                    <span class="content a-c-master">{{ form.contact_info.contact_id_doc_type | initDic(contact_id_doc_typeDic) }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">超级管理员证件号码</span>
                                    <span class="content a-c-master">{{ form.contact_info.contact_id_card_number || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">超级管理员证件正面照片</span>
                                    <div class="content">
                                        <el-image 
                                            class="infoImg" 
                                            v-for="(item,index) in form.contact_info.contact_id_doc_copyImg"
                                            :key="index"
                                            :preview-src-list="form.contact_info.contact_id_doc_copyImg" 
                                            :src="item" fit="fill" :lazy="true"></el-image>
                                    </div>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">超级管理员证件反面照片</span>
                                    <div class="content">
                                        <el-image 
                                            class="infoImg" 
                                            v-for="(item,index) in form.contact_info.contact_id_doc_copy_backImg"
                                            :key="index"
                                            :preview-src-list="form.contact_info.contact_id_doc_copy_backImg" 
                                            :src="item" fit="fill" :lazy="true"></el-image>
                                    </div>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">超级管理员证件有效期开始时间</span>
                                    <span class="content a-c-master">{{ form.contact_info.contact_id_doc_period_begin || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">超级管理员证件有效期结束时间</span>
                                    <span class="content a-c-master">{{ form.contact_info.contact_id_doc_period_end || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">业务办理授权函</span>
                                    <div class="content">
                                        <el-image 
                                            class="infoImg" 
                                            v-for="(item,index) in form.contact_info.business_authorization_letterImg"
                                            :key="index"
                                            :preview-src-list="form.contact_info.business_authorization_letterImg" 
                                            :src="item" fit="fill" :lazy="true"></el-image>
                                    </div>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">超级管理员手机</span>
                                    <span class="content a-c-master">{{ form.contact_info.mobile_phone || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">超级管理员邮箱</span>
                                    <span class="content a-c-master">{{ form.contact_info.contact_email || '-' }}</span>
                                </div>
                            </div>
                        </el-card>
                        <div class="tips">
                            <span>超级管理员需在开户后进行签约，并可接收日常重要管理信息和进行资金操作，请确定其为商户法定代表人或负责人。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="店铺信息" prop="sales_scene_info">
                        <el-button icon="el-icon-plus" size="mini" type="primary" v-if="editType != 'info'" @click="addSalesSceneInfo">{{ form.sales_scene_info?'修改':'添加' }}</el-button>
                        <el-card shadow="hover" class="a-mt-20 " :body-style="{ padding: '10px' }" v-if="form.sales_scene_info">
                            <div class="a-flex-rfsfs a-flex-wrap">
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">店铺名称</span>
                                    <span class="content a-c-master">{{ form.sales_scene_info.store_name || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">店铺链接</span>
                                    <span class="content a-c-master">{{ form.sales_scene_info.store_url || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">店铺二维码</span>
                                    <div class="content">
                                        <el-image 
                                            class="infoImg" 
                                            v-for="(item,index) in form.sales_scene_info.store_qr_codeImg"
                                            :key="index"
                                            :preview-src-list="form.sales_scene_info.store_qr_codeImg" 
                                            :src="item" fit="fill" :lazy="true"></el-image>
                                    </div>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">小程序AppID</span>
                                    <span class="content a-c-master">{{ form.sales_scene_info.mini_program_sub_appid || '-' }}</span>
                                </div>
                            </div>
                        </el-card>
                        <div class="tips">
                            <span>超级管理员需在开户后进行签约，并可接收日常重要管理信息和进行资金操作，请确定其为商户法定代表人或负责人。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="结算规则" prop="settlement_info">
                        <el-button icon="el-icon-plus" size="mini" type="primary" v-if="editType != 'info'" @click="addSettlementInfo">{{ form.settlement_info?'修改':'添加' }}</el-button>
                        <el-card shadow="hover" class="a-mt-20 " :body-style="{ padding: '10px' }" v-if="form.settlement_info">
                            <div class="a-flex-rfsfs a-flex-wrap">
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">结算规则ID</span>
                                    <span class="content a-c-master">{{ form.settlement_info.settlement_id | initDic(settlement_idDic) }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">所属行业</span>
                                    <span class="content a-c-master">{{ form.settlement_info.qualification_type | initDic(qualification_typeDic) }}</span>
                                </div>
                            </div>
                        </el-card>
                        <div class="tips">
                            <span>请填写商家的结算费率规则、所属行业等信息。若电商平台未传入，系统将填写默认值。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="商户简称" prop="merchant_shortname">
                        <el-input v-model="form.merchant_shortname" placeholder="请输入商户简称" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>最多21个汉字。将在支付完成页向买家展示，需与商家的实际售卖商品相符。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="特殊资质" prop="qualificationsImg">
                        <le-upload-img-wx 
                            :readonly="editType == 'info'" 
                            notnull 
                            ref="icon" 
                            label="" 
                            :limit="5" 
                            :imgList.sync="form.qualificationsImg" 
                            :mediaList.sync="form.qualifications">
                        </le-upload-img-wx>
                        <div class="tips">
                            <span>
                                1.仅当商户选择了必需提交特殊资质的行业时，需要提供该项资料；若商户选择了无需特殊资质的行业，或未选择行业时，无需提交该项资料，详情查看
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/220228qEfuAz220228bMFji6.html')">《行业对应特殊资质》</span>
                            </span>
                            <span>2.请提供为“申请商家主体”所属的特殊资质，可授权使用总公司/分公司的特殊资质。</span>
                            <span>3.最多可上传5张照片。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="补充材料" prop="business_addition_picsImg">
                        <le-upload-img-wx :readonly="editType == 'info'" notnull ref="icon" label="" :limit="15" :imgList.sync="form.business_addition_picsImg" :mediaList.sync="form.business_addition_pics"></le-upload-img-wx>
                        <div class="tips">
                            <span>1.最多可上传15张照片。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="补充说明" prop="business_addition_desc">
                        <el-input type="textarea" autosize v-model="form.business_addition_desc" placeholder="请输入补充说明" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>若主体为“个人卖家”，该字段必传，则需填写描述“该商户已持续从事电子商务经营活动满6个月，且期间经营收入累计超过20万元。”</span>
                        </div>
                    </el-form-item>
                </div>
                <div v-if="form.servicePlatform == 2">
                    <el-form-item label="结算账户信息" prop="account_info">
                        <el-button icon="el-icon-plus" size="mini" type="primary" v-if="editType != 'info'" @click="addAccountInfo">{{ form.account_info?'修改':'添加' }}</el-button>
                        <el-card shadow="hover" class="a-mt-20 " :body-style="{ padding: '10px' }" v-if="form.account_info">
                            <div class="a-flex-rfsfs a-flex-wrap">
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">账户类型</span>
                                    <span class="content a-c-master">{{ form.account_info.bank_account_type | initDic(bank_account_typeDic) }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户银行</span>
                                    <span class="content a-c-master">{{ form.account_info.account_bank }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户银行别名</span>
                                    <span class="content a-c-master">{{ form.account_info.bank_alias }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户名称</span>
                                    <span class="content a-c-master">{{ form.account_info.account_name || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户人身份证号</span>
                                    <span class="content a-c-master">{{ form.contact_info.contact_id_card_number || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户人手机号</span>
                                    <span class="content a-c-master">{{ form.contact_info.mobile_phone || '-' }}</span>
                                </div>
                                <!-- <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户名称</span>
                                    <span class="content a-c-master">{{ form.account_info.account_name || '-' }}</span>
                                </div> -->
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户银行省市编码</span>
                                    <span class="content a-c-master">{{ form.account_info.bank_address_code || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户银行联行号</span>
                                    <span class="content a-c-master">{{ form.account_info.bank_branch_id || '-' }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">开户银行全称（含支行）</span>
                                    <span class="content a-c-master">{{ form.account_info.bank_name }}</span>
                                </div>
                                <div class="a-flex-rfsfs mt21 mr80">
                                    <span class="title c79">银行账号</span>
                                    <span class="content a-c-master">{{ form.account_info.account_number || '-' }}</span>
                                </div>
                            </div>
                        </el-card>
                        <div class="tips">
                            <span>请填写商家提现收款的银行账户信息</span>
                        </div>
                    </el-form-item>
                </div>
            </el-form>
            <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox" v-if="editType != 'info'">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div>
        </el-card>
        <business-license-info :organization_type="form.organization_type" ref="businessLicenseInfo" @change="businessLicenseInfoChange"></business-license-info>
        <finance-institution-info ref="financeInstitutionInfo" @change="financeInstitutionInfoChange"></finance-institution-info>
        <id-card-info :organization_type="form.organization_type" ref="idCardInfo" @change="idCardInfoChange"></id-card-info>
        <id-doc-info :organization_type="form.organization_type" :id_doc_type="form.id_doc_type" ref="idDocInfo" @change="idDocInfoChange"></id-doc-info>
        <ubo-info-list ref="uboInfoList" @change="uboInfoListChange"></ubo-info-list>
        <account-info ref="accountInfo" @change="accountInfoChange"></account-info>
        <contact-info :organization_type="form.organization_type" :servicePlatform='form.servicePlatform' ref="contactInfo" @change="contactInfoChange"></contact-info>
        <sales-scene-info ref="salesSceneInfo" @change="salesSceneInfoChange"></sales-scene-info>
        <settlement-info ref="settlementInfo" @change="settlementInfoChange"></settlement-info>
    </div>
</template>

<script>
import AccountInfo from './child/accountInfo.vue';
    import businessLicenseInfo from './child/businessLicenseInfo.vue';
import FinanceInstitutionInfo from './child/financeInstitutionInfo.vue';
import IdCardInfo from './child/idCardInfo.vue';
import IdDocInfo from './child/idDocInfo.vue';
import UboInfoList from './child/uboInfoList.vue';
import contactInfo from './child/contactInfo.vue';
import SalesSceneInfo from './child/salesSceneInfo.vue';
import SettlementInfo from './child/settlementInfo.vue';
import LeUploadImgWx from '../components/form/le-upload-img-wx.vue';
    export default {
        components:{
            businessLicenseInfo,
                FinanceInstitutionInfo,
                IdCardInfo,
                IdDocInfo,
                UboInfoList,
                AccountInfo,
                contactInfo,
                SalesSceneInfo,
                SettlementInfo,
                LeUploadImgWx
        },
        data() {
            var checjAuthorize_letter_copyImg = (rule, value, callback) => {
                if (this.form.id_holder_type == 'SUPER') {
                    if(!this.form.authorize_letter_copyImg) {
                        callback(new Error('请上传法定代表人说明函'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkId_card_info = (rule, value, callback) => {
                if (this.form.id_holder_type == 'LEGAL' && this.form.id_doc_type == 'IDENTIFICATION_TYPE_MAINLAND_IDCARD') {
                    if(!this.form.id_card_info) {
                        callback(new Error('请添加经营者/法人身份证信息'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkId_doc_info = (rule, value, callback) => {
                if (this.form.id_holder_type == 'LEGAL' && this.form.id_doc_type != 'IDENTIFICATION_TYPE_MAINLAND_IDCARD') {
                    if(!this.form.id_doc_info) {
                        callback(new Error('请添加经营者/法人其他类型证件信息'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkBusiness_addition_desc = (rule, value, callback) => {
                if (this.form.organization_type == '2500') {
                    if(!this.form.business_addition_desc) {
                        callback(new Error('请填写补充说明'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkUbo_info_list = (rule, value, callback) => {
                if (this.form.owner == '0') {
                    if(!this.form.ubo_info_list.length) {
                        callback(new Error('请至少添加一位最终受益人信息'));
                    }else{
                        callback();
                    }
                } else {
                    console.log(111);
                    callback();
                }
            };
            return {
                id: '',
                editType: '',
                form: {
                    companyId: '',
                    out_request_no: '',//业务申请编号
                    organization_type: '',//主体类型
                    finance_institution: '0',//是否金融机构
                    business_license_info: '',//营业执照/登记证书信息
                    finance_institution_info: '',//金融机构许可证信息
                    id_holder_type: '',//证件持有人类型
                    id_doc_type: 'IDENTIFICATION_TYPE_MAINLAND_IDCARD',//经营者/法人证件类型,默认身份证
                    authorize_letter_copyImg: [],//法定代表人说明函图片
                    authorize_letter_copy: [],//法定代表人说明函MediaID
                    id_card_info: '',//经营者/法人身份证信息
                    id_doc_info: '',//经营者/法人其他类型证件信息
                    owner: '',//经营者/法人是否为受益人
                    ubo_info_list: [],//最终受益人信息列表
                    account_info: '',//结算账户信息
                    contact_info: '',//超级管理员信息
                    sales_scene_info: '',//店铺信息
                    settlement_info: '',//结算规则
                    merchant_shortname: '',//商户简称
                    qualificationsImg: [],//特殊资质图片
                    qualifications: [],//特殊资质MediaID
                    business_addition_picsImg: [],//补充材料图片
                    business_addition_pics: [],//补充材料MediaID
                    business_addition_desc: '',//补充说明	
                    servicePlatform: 1,//进件类型
                },
                rules: {
                    companyId: [{required: true, message:'请选择商户', trigger: 'change'}],
                    organization_type: [{required: true, message:'请选择主体类型', trigger: 'change'}],
                    finance_institution_info: [{required: true, message:'请添加金融机构许可证信息', trigger: 'change'}],
                    business_license_info: [{required: true, message:'请添加营业执照/登记证书信息', trigger: 'change'}],
                    authorize_letter_copyImg: [{ validator: checjAuthorize_letter_copyImg, trigger: 'change' }],
                    id_card_info: [{ validator: checkId_card_info, trigger: 'change' }],//经营者/法人身份证信息
                    id_doc_info: [{ validator: checkId_doc_info, trigger: 'change' }],//经营者/法人其他类型证件信息
                    owner: [{required: true, message:'请选择经营者/法人是否为受益人', trigger: 'change'}],//经营者/法人是否为受益人
                    ubo_info_list: [{ validator: checkUbo_info_list, trigger: 'change' }],//最终受益人信息
                    account_info: [{required: true, message:'请添加结算账户信息', trigger: 'change'}],
                    contact_info: [{required: true, message:'请添加超级管理员信息', trigger: 'change'}],
                    sales_scene_info: [{required: true, message:'请添加店铺信息', trigger: 'change'}],
                    merchant_shortname: [{required: true, message:'请填写商户简称', trigger: 'blur'}],
                    business_addition_desc: [{ validator: checkBusiness_addition_desc, trigger: 'change' }],//补充说明
                },
                organization_typeDic: [],//主体类型字典
                yesOrNoDic: [],//是否单选字典
                id_holder_typeDic: [],//证件持有人类型字典
                id_doc_typeDic: [],//
                bank_account_typeDic: [],
                cert_typeDic: [],
                contact_typeDic: [],
                contact_id_doc_typeDic: [],
                finance_typeDic: [],
                settlement_idDic: [],
                qualification_typeDic: [],
                ubo_id_doc_typeDic: [],
                servicePlatformDic: [{
                    label: '微信进件',
                    value: 1
                },{
                    label: '宝付进件',
                    value: 2
                }],//进件类型字典
            };
        },
        created () {
            this.$getDic('yesOrNo').then(res=>{ this.yesOrNoDic = res; })
            this.$getDic('organization_type','select').then(res=>{ this.organization_typeDic = res; })
            this.$getDic('id_holder_type','select').then(res=>{ this.id_holder_typeDic = res; })
            this.$getDic('id_doc_type','select').then(res=>{ this.id_doc_typeDic = res; })
            this.$getDic('bank_account_type','select').then(res=>{ this.bank_account_typeDic = res; })
            this.$getDic('cert_type','select').then(res=>{ this.cert_typeDic = res; })
            this.$getDic('contact_type','select').then(res=>{ this.contact_typeDic = res; })
            this.$getDic('id_doc_type','select').then(res=>{ this.contact_id_doc_typeDic = res; })
            this.$getDic('finance_type','select').then(res=>{ this.finance_typeDic = res; })
            this.$getDic('settlement_id','select').then(res=>{ this.settlement_idDic = res; })
            this.$getDic('qualification_type','select').then(res=>{ this.qualification_typeDic = res; })
            this.$getDic('id_doc_type','select').then(res=>{ this.ubo_id_doc_typeDic = res; })
            this.id = this.$route.query.id
            this.editType = this.$route.query.editType
            if(this.id) {
                this.getInfo()
            }
        },
        computed: {
            
        },
        mounted () {
            
        },
        methods: {
            // 查询申请详情
            getInfo () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.merchantDetails,
                    method: "get",
                    params: {
                        id: this.id
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.form = {
                            ...res.result.data,
                            finance_institution: res.result.data.finance_institution ? '1' : '0',
                            owner: res.result.data.owner ? '1' : '0',
                        }
                        
                        for (let key in this.form) {
                            let item = this.form[key]
                            if(Object.prototype.toString.call(item) === '[object Object]'){ // 如果是对象
                                for(let k in item){
                                    let ite = item[k]
                                    if(typeof ite === 'string'){ // 如果是string
                                        if(k.indexOf('Img') != -1){
                                            item[k] = JSON.parse(item[k])
                                            let mediaKey = k.substring(0, k.length - 3)
                                            if(item[k].length > 1){
                                                item[mediaKey] = JSON.parse(item[mediaKey])
                                            }else{
                                                item[mediaKey] = [item[mediaKey]]
                                            }
                                        }else if(k == 'business_time'){
                                            item[k] = JSON.parse(item[k])
                                        }
                                    }
                                }
                            }else if(typeof item === 'string'){ // 如果是string
                                if(key.indexOf('Img') != -1){
                                    this.form[key] = JSON.parse(this.form[key])
                                    let mediaKey = key.substring(0, key.length - 3)
                                    if(this.form[key].length > 1){
                                        this.form[mediaKey] = JSON.parse(this.form[mediaKey])
                                    }else{
                                        this.form[mediaKey] = [this.form[mediaKey]]
                                    }
                                }
                            }else if(key == 'ubo_info_list' && this.form[key] && this.form[key].length){
                                this.form[key].map(ubo_info_list_item=>{
                                    ubo_info_list_item.ubo_id_doc_copy = [ubo_info_list_item.ubo_id_doc_copy]
                                    ubo_info_list_item.ubo_id_doc_copyImg = JSON.parse(ubo_info_list_item.ubo_id_doc_copyImg)

                                    ubo_info_list_item.ubo_id_doc_copy_back = [ubo_info_list_item.ubo_id_doc_copy_back]
                                    ubo_info_list_item.ubo_id_doc_copy_backImg = JSON.parse(ubo_info_list_item.ubo_id_doc_copy_backImg)
                                })
                            }
                        }
                        for(let key in this.form) {
                            if(this.isNullObj(this.form[key])){
                                this.form[key] = ''
                            }
                        }
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{
                    console.log(err);
                })
            },
            // 进件类型修改
            servicePlatformChange () {
                
            },
            // 主体类型修改
            organization_typeChange (e) {
                if(e == 2502){
                    this.form.id_doc_type = this.id_doc_typeDic[0].value
                }
                this.form.business_license_info = ''
                this.form.finance_institution_info = ''
                this.form.id_holder_type = ''
                this.form.id_card_info = '' 
                this.form.id_doc_info = '' 
                this.form.owner = ''
                this.form.contact_info = ''
                this.form.ubo_info_list = []
            },
            // 添加营业执照/登记证书信息
            addBusinessLicenseInfo () {
                if(this.form.business_license_info){
                    this.$refs['businessLicenseInfo'].form = {
                        ...this.form.business_license_info
                    }
                    if(this.form.business_license_info.business_time[1] == '长期'){
                        this.$refs['businessLicenseInfo'].business_timeType = true
                    }
                }
                this.$refs['businessLicenseInfo'].dialogVisible = true
            },
            // 添加金融机构许可证信息
            addFinanceInstitutionInfo () {
                if(this.form.finance_institution_info){
                    this.$refs['financeInstitutionInfo'].form = {
                        ...this.form.finance_institution_info
                    }
                }
                this.$refs['financeInstitutionInfo'].dialogVisible = true
            },
            // 证件持有人类型修改
            id_holder_typeChange () {

            },
            // 经营者/法人证件类型修改
            id_doc_typeChange () {
                this.form.id_card_info = '' 
                this.form.id_doc_info = '' 
            },
            // 经营者/法人是否为受益人修改
            ownerChange () {
                if(this.form.owner == '1' && this.form.ubo_info_list.length > 3){
                    this.$message.error(`经营者/法人是最终受益人时，最终受益人最多添加3位`)
                    this.form.ubo_info_list.splice(this.form.ubo_info_list.length - 1, 1)
                }
            },
            // 添加法人身份证信息
            addIdCardInfo () {
                if(this.form.id_card_info){
                    this.$refs['idCardInfo'].form = {
                        ...this.form.id_card_info
                    }
                    if(this.form.id_card_info.id_card_valid_time == '长期'){
                        this.$refs['idCardInfo'].business_timeType = true
                    }
                }
                this.$refs['idCardInfo'].dialogVisible = true
            },
            // 经营者/法人其他类型证件信息
            addIdDocInfo () {
                if(this.form.id_doc_info){
                    this.$refs['idDocInfo'].form = {
                        ...this.form.id_doc_info
                    }
                    if(this.form.id_doc_info.doc_period_end == '长期'){
                        this.$refs['idDocInfo'].business_timeType = true
                    }
                }
                this.$refs['idDocInfo'].dialogVisible = true
            },
            // 添加最终受益人
            addUboInfoList () {
                let limit = this.form.owner=='1'?3:4
                if(this.form.ubo_info_list.length >= limit) {
                    this.$message.error(`经营者/法人是最终受益人时，最终受益人最多添加3位。经营者/法人不是最终受益人时，最终受益人最多添加4位`)
                    return
                }
                this.$refs['uboInfoList'].dialogVisible = true
            },
            //添加结算账户信息
            addAccountInfo () {
                if(this.form.account_info){
                    this.$refs['accountInfo'].form = {
                        ...this.form.account_info
                    }
                    // this.$refs['accountInfo'].bank_account_typeChange(this.form.account_info.bank_account_type)
                }
                this.$refs['accountInfo'].dialogVisible = true
            },
            //添加超级管理员信息
            addContactInfo () {
                if(this.form.contact_info){
                    this.$refs['contactInfo'].form = {
                        ...this.form.contact_info
                    }
                    if(this.form.contact_info.contact_id_doc_period_end == '长期'){
                        this.$refs['contactInfo'].business_timeType = true
                    }
                }
                this.$refs['contactInfo'].dialogVisible = true
            },
            // 添加店铺信息
            addSalesSceneInfo () {
                if(this.form.sales_scene_info){
                    this.$refs['salesSceneInfo'].form = {
                        ...this.form.sales_scene_info
                    }
                }
                this.$refs['salesSceneInfo'].dialogVisible = true
            },
            // 添加结算规则 
            addSettlementInfo () {
                if(this.form.settlement_info){
                    this.$refs['settlementInfo'].form = {
                        ...this.form.settlement_info
                    }
                }
                this.$refs['settlementInfo'].dialogVisible = true
            },
            businessLicenseInfoChange (datas) {
                this.form.business_license_info = datas
            },
            financeInstitutionInfoChange (datas) {
                this.form.finance_institution_info = datas
            },
            idCardInfoChange (datas) {
                this.form.id_card_info = datas
            },
            idDocInfoChange (datas) {
                this.form.id_doc_info = datas
            },
            uboInfoListChange (datas) {
                this.form.ubo_info_list.push({
                    ...datas
                })
            },
            accountInfoChange (datas) {
                this.form.account_info = datas
                if(this.form.servicePlatform == 2) {
                    // 如果是宝付进件，则在这里填充contact_info信息
                    this.form.contact_info = {
                        contact_id_card_number: datas.contact_id_card_number,
                        mobile_phone: datas.mobile_phone
                    }
                }
            },
            contactInfoChange (datas) {
                this.form.contact_info = datas
            },
            salesSceneInfoChange (datas) {
                this.form.sales_scene_info = datas
            },
            settlementInfoChange (datas) {
                this.form.settlement_info = datas
            },
            deleteUbo_info_item (index) {
                this.$confirm('是否确认删除该最终收益人？','温馨提示')
                .then(_=>{
                    this.form.ubo_info_list.splice(index, 1)
                })
                .catch(_=>{})
            },
            submit () {
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        let reqData = {}
                        for(let key in this.form) {
                            let item = this.form[key]
                            if(Object.prototype.toString.call(item) === '[object Object]' && item != {}){
                                let reqDataItem = {}
                                for(let k in item) {
                                    if(Object.prototype.toString.call(item[k]) === '[object Object]' && item[k] != {}){
                                        reqDataItem[k] = item[k]
                                    }else if(typeof item[k] === 'number'){
                                        reqDataItem[k] = item[k]
                                    }else{
                                        if(item[k] && item[k].length){
                                            reqDataItem[k] = item[k]
                                        }
                                    }
                                }
                                reqData[key] = reqDataItem
                            }else if(Object.prototype.toString.call(item) === '[object Array]'){
                                if(this.form[key] && this.form[key].length){
                                    reqData[key] = this.form[key]
                                }
                            }else{
                                if(this.form[key]){
                                    reqData[key] = this.form[key]
                                }
                            }
                        }
                        reqData = JSON.parse(JSON.stringify(reqData))
                        for (let key in reqData) {
                            let item = reqData[key]
                            if(Object.prototype.toString.call(item) === '[object Object]'){ // 如果是对象
                                for(let k in item){
                                    let ite = item[k]
                                    if(Object.prototype.toString.call(ite) === '[object Array]'){ // 如果是数组
                                        if(k.indexOf('Img') != -1){
                                            item[k] = JSON.stringify(item[k])
                                            let mediaKey = k.substring(0, k.length - 3)
                                            if(ite.length > 1){
                                                item[mediaKey] = JSON.stringify(item[mediaKey])
                                            }else{
                                                item[mediaKey] = item[mediaKey][0]?item[mediaKey][0]:''
                                            }
                                        }else if(k == 'business_time'){
                                            item[k] = JSON.stringify(item[k])
                                        }
                                    }
                                }
                            }else if(Object.prototype.toString.call(item) === '[object Array]'){ // 如果是数组
                                if(key.indexOf('Img') != -1){
                                    reqData[key] = JSON.stringify(reqData[key])
                                    let mediaKey = key.substring(0, key.length - 3)
                                    if(item.length > 1){
                                        reqData[mediaKey] = JSON.stringify(reqData[mediaKey])
                                    }else{
                                        reqData[mediaKey] = reqData[mediaKey][0]?reqData[mediaKey][0]:''
                                    }
                                }else if(key == 'ubo_info_list'){
                                    let ubo_info_list = reqData[key]
                                    ubo_info_list.map(ubo_info_list_item=>{
                                        ubo_info_list_item.ubo_id_doc_copy = ubo_info_list_item.ubo_id_doc_copy[0]?ubo_info_list_item.ubo_id_doc_copy[0]:''
                                        ubo_info_list_item.ubo_id_doc_copyImg = JSON.stringify(ubo_info_list_item.ubo_id_doc_copyImg)

                                        ubo_info_list_item.ubo_id_doc_copy_back = ubo_info_list_item.ubo_id_doc_copy_back[0]?ubo_info_list_item.ubo_id_doc_copy_back[0]:''
                                        ubo_info_list_item.ubo_id_doc_copy_backImg = JSON.stringify(ubo_info_list_item.ubo_id_doc_copy_backImg)
                                    })
                                }
                            }
                        }
                        reqData.finance_institution = reqData.finance_institution == 1 ? true : false
                        reqData.owner = reqData.owner == 1 ? true : false

                        this.$Axios._post({
                            url: this.$Config.apiUrl.merchantAdd,
                            method: "post",
                            params: reqData
                        })
                        .then(res=>{
                            if (res.result.code == 0) {
                                this.$message.success('操作成功')
                                this.$router.back()
                                this.$store.dispatch("delView", this.$route);
                            } else {
                                this.$message.error(res.result.message)
                            }
                        })
                        .catch(err=>{

                        })
                    }else{
                        this.$message.error('请检查所填参数是否正确！！！')
                    }
                })
                
            },
            cancelSubmit () {
                this.$confirm('是否确认返回？','温馨提示')
                .then(_=>{
                    this.$router.back()
                    this.$store.dispatch("delView", this.$route);
                })
                .catch(_=>{})
            },
            //数组去重
            uniqueArr (arr) {
                try{
                    for(var i=0; i<arr.length; i++){
                        for(var j=i+1; j<arr.length; j++){
                            if(arr[i].mobile==arr[j].mobile && arr[i].companyId==arr[j].companyId){         //第一个等同于第二个，splice方法删除第二个
                                arr.splice(j,1);
                                j--;
                            }
                        }
                    }
                    return arr;
                }catch(err){
                }
            },
            isNullObj (object) {
                let isEmpty = true
                if(Object.prototype.toString.call(object) === '[object Object]'){
                    Object.keys(object).forEach(el => {
                        if (object[el] !== null && object[el] !== '') {
                            isEmpty = false
                        }
                    })
                }else{
                    isEmpty = false
                }
                
                return isEmpty
            },
            windowOpen (url) {
                window.open(url)
            }
        },
    }
</script>

<style lang="scss" scoped>
    .editWarningUser{
        font-size: 14px;
        height: 100%;
        overflow-y: auto;
        .tips{
            font-size: 12px;
            color: #909399;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            line-height: 22px;
        }
        .mt64{
            margin-top: 64px
        }
        .mt21{
            margin-top: 12px
        }
        .mr80{
            margin-right: 80px;
        }
        .c79{
            color: #797979
        }
        .title{
            width: 150px;
            margin-right: 16px;
        }
        .content{
            width: 240px
        }
        .infoImg{
            width: 100px;
            height: 100px;
            margin-right: 10px;
            border-radius: 4px;
        }
        .ubo_info_list_card{
            position: relative;
        }
        .deleteUbo_info_list{
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 18px;
            color: #666666;
        }
    }
</style>