<template>
    <div>
        <el-dialog
            title="超级管理员信息"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                    <el-form-item prop="contact_type" label="超级管理员类型">
                        <el-select v-model="form.contact_type" clearable @change="contact_typeChange">
                            <el-option v-for="item in contact_typeDic"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="tips">
                            <span>1.主体为“小微/个人卖家 ”，可选择：65-经营者/法人。</span>
                            <span>2.主体为“个体工商户/企业/政府机关/事业单位/社会组织”，可选择：65-经营者/法人、66- 经办人。 （经办人：经商户授权办理微信支付业务的人员）。</span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="contact_name" label="超级管理员姓名">
                        <el-input maxlength="100" v-model="form.contact_name" placeholder="请输入超级管理员姓名" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.若管理员类型为“法人”，则该姓名需与法人身份证姓名一致。</span>
                            <span>2.若管理员类型为“经办人”，则可填写实际负责人的姓名。</span>
                            <span>3.前后不能有空格、制表符、换行符，不能仅含数字、特殊字符，仅能填写数字、英文字母、汉字及特殊字符。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="超级管理员证件类型" prop="contact_id_doc_type">
                        <el-select v-model="form.contact_id_doc_type" clearable @change="contact_id_doc_typeChange">
                            <el-option v-for="item in contact_id_doc_typeDic"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="tips">
                            <span>1.当超级管理员类型是经办人时，请上传超级管理员证件类型。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="超级管理员证件号码" prop="contact_id_card_number">
                        <el-input maxlength="100" v-model="form.contact_id_card_number" placeholder="请输入超级管理员证件号码" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.若超级管理员类型为法人，则该身份证号码需与法人身份证号码一致。若超级管理员类型为经办人，则可填写实际经办人的身份证号码。</span>
                            <span>1.可传身份证、来往内地通行证、来往大陆通行证、护照等证件号码。</span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="contact_id_doc_copyImg" label="超级管理员证件正面照片">
                        <le-upload-img-wx notnull ref="contact_id_doc_copyImg" label="" :limit="1" :imgList.sync="form.contact_id_doc_copyImg" :mediaList.sync="form.contact_id_doc_copy"></le-upload-img-wx>
                        <div class="tips">
                            <span>1.当超级管理员类型是经办人时，请上传超级管理员证件的正面照片。</span>
                            <span>2.若证件类型为身份证，请上传人像面照片。</span>
                            <span>3.照片应正面拍摄、清晰、四角完整、无反光或遮挡；不得翻拍、截图、镜像、PS。</span>
                            <span>
                                4.请上传彩色照片or彩色扫描件，复印件需加盖公章鲜章，可添加“微信支付”相关水印（如微信支付认证），见
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/230615MjMBRf230615NNn2yY.html')">【指引文档】</span>
                            </span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="contact_id_doc_copy_backImg" label="超级管理员证件反面照片">
                        <le-upload-img-wx notnull ref="contact_id_doc_copy_backImg" label="" :limit="1" :imgList.sync="form.contact_id_doc_copy_backImg" :mediaList.sync="form.contact_id_doc_copy_back"></le-upload-img-wx>
                        <div class="tips">
                            <span>1.当超级管理员类型是经办人时，请上传超级管理员证件的反面照片。</span>
                            <span>2.若证件类型为身份证，请上传国徽面照片。</span>
                            <span>3.若证件类型为护照，无需上传反面照片。</span>
                            <span>4.照片应正面拍摄、清晰、四角完整、无反光或遮挡；不得翻拍、截图、镜像、PS。</span>
                            <span>
                                5.请上传彩色照片or彩色扫描件，复印件需加盖公章鲜章，可添加“微信支付”相关水印（如微信支付认证），见
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/230615MjMBRf230615NNn2yY.html')">【指引文档】</span>
                            </span>
                        </div>
                    </el-form-item>
                    <el-form-item label="超级管理员证件有效期开始时间" prop="contact_id_doc_period_begin">
                        <el-date-picker
                            v-model="form.contact_id_doc_period_begin"
                            type="date"
                            :value-format="'yyyy-MM-dd'"
                            placeholder="选择日期">
                        </el-date-picker>
                        <div class="tips">
                            <span>1.当超级管理员类型是经办人时，请上传证件有效期开始时间。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="超级管理员证件有效期结束时间" prop="contact_id_doc_period_end">
                        <el-date-picker
                            :disabled="business_timeType"
                            v-model="form.contact_id_doc_period_end"
                            type="date"
                            :value-format="'yyyy-MM-dd'"
                            placeholder="选择日期">
                        </el-date-picker>
                        <el-checkbox class="a-mlr-12" v-model="business_timeType" @change='business_timeTypeChange'>长期</el-checkbox>
                        <div class="tips">
                            <span>1.当超级管理员类型是经办人时，请上传证件有效期结束时间。</span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="business_authorization_letterImg" label="业务办理授权函">
                        <le-upload-img-wx notnull ref="business_authorization_letterImg" label="" :limit="1" :imgList.sync="form.business_authorization_letterImg" :mediaList.sync="form.business_authorization_letter"></le-upload-img-wx>
                        <div class="tips">
                            <span>1.当超级管理员类型是经办人时，请上传业务办理授权函。</span>
                            <span>
                                2.请参照
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/220509Y3Yvym220509fQvYR7.html')">示例图</span>
                                打印业务办理授权函，全部信息需打印，不支持手写商户信息，并加盖公章。
                            </span>
                        </div>
                    </el-form-item>
                    <el-form-item label="超级管理员手机" prop="mobile_phone">
                        <el-input maxlength="100" v-model="form.mobile_phone" placeholder="请输入超级管理员手机" style="width: 400px"></el-input>
                    </el-form-item>
                    <el-form-item label="超级管理员邮箱" prop="contact_email">
                        <el-input maxlength="100" v-model="form.contact_email" placeholder="请输入超级管理员邮箱" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.主体类型为“小微商户/个人卖家”可选填，其他主体需必填。</span>
                            <span>2.用于接收微信支付的开户邮件及日常业务通知。</span>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
import LeUploadImgWx from '../../components/form/le-upload-img-wx.vue';
    export default {
        components:{
            LeUploadImgWx
        },
        data() {
            var checkContact_id_doc_type = (rule, value, callback) => {
                if (this.form.contact_type == '66') {
                    if(!this.form.contact_id_doc_type) {
                        callback(new Error('请选择超级管理员证件类型'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkContact_id_doc_copyImg = (rule, value, callback) => {
                if (this.form.contact_type == '66') {
                    if(!this.form.contact_id_doc_copyImg) {
                        callback(new Error('请上传超级管理员证件正面照片'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkContact_id_doc_copy_backImg = (rule, value, callback) => {
                if (this.form.contact_type == '66') {
                    if(!this.form.contact_id_doc_copyImg && this.form.contact_id_doc_type != 'IDENTIFICATION_TYPE_OVERSEA_PASSPORT') {
                        callback(new Error('请上传超级管理员证件正面照片'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkContact_id_doc_period_begin = (rule, value, callback) => {
                if (this.form.contact_type == '66') {
                    if(!this.form.contact_id_doc_period_begin) {
                        callback(new Error('请选择超级管理员证件有效期开始时间'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkContact_id_doc_period_end = (rule, value, callback) => {
                if (this.form.contact_type == '66') {
                    if(!this.form.contact_id_doc_period_end && !this.business_timeType) {
                        callback(new Error('请选择超级管理员证件有效期结束时间'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkBusiness_authorization_letterImg = (rule, value, callback) => {
                if (this.form.contact_type == '66') {
                    if(!this.form.business_authorization_letterImg) {
                        callback(new Error('请上传业务办理授权函'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkContact_email = (rule, value, callback) => {
                if (this.organization_type != '2401' && this.organization_type != '2500') {
                    if(!this.form.contact_email) {
                        callback(new Error('请输入超级管理员邮箱'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkContact_id_card_number = (rule, value, callback) => {
                if (this.form.contact_type == '66') {
                    if(!this.form.contact_id_card_number) {
                        callback(new Error('请填写超级管理员证件号码'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            return {
                dialogVisible: false,
                form: {
                    contact_type: '',//超级管理员类型		
                    contact_name: '',//超级管理员姓名	
                    contact_id_doc_type: '',//超级管理员证件类型	
                    contact_id_card_number: '',//超级管理员证件号码	
                    contact_id_doc_copyImg: [],//超级管理员证件正面照片	
                    contact_id_doc_copy: [],//超级管理员证件正面照片	MediaID
                    contact_id_doc_copy_backImg: [],//超级管理员证件反面照片
                    contact_id_doc_copy_back: [],//超级管理员证件反面照片	MediaID
                    contact_id_doc_period_begin: '',//超级管理员证件有效期开始时间	
                    contact_id_doc_period_end: '',//超级管理员证件有效期结束时间	
                    business_authorization_letterImg: [],//业务办理授权函	
                    business_authorization_letter: [],//业务办理授权函	MediaID
                    mobile_phone: '',//超级管理员手机	
                    contact_email: '',//超级管理员邮箱		
                },
                rules: {
                    contact_type: [{required: true, message:'请选择超级管理员类型', trigger: 'change'}],//超级管理员类型		
                    contact_name: [{required: true, message:'请输入超级管理员姓名', trigger: 'blur'}],//超级管理员姓名	
                    contact_id_doc_type: [{ validator: checkContact_id_doc_type, trigger: 'change' }],//超级管理员证件类型	
                    contact_id_card_number: [{ validator: checkContact_id_card_number, trigger: 'change' }],//超级管理员证件号码	
                    contact_id_doc_copyImg: [{ validator: checkContact_id_doc_copyImg, trigger: 'change' }],//超级管理员证件正面照片	
                    contact_id_doc_copy_backImg: [{ validator: checkContact_id_doc_copy_backImg, trigger: 'change' }],//超级管理员证件反面照片
                    contact_id_doc_period_begin: [{ validator: checkContact_id_doc_period_begin, trigger: 'change' }],//超级管理员证件有效期开始时间	
                    contact_id_doc_period_end: [{ validator: checkContact_id_doc_period_end, trigger: 'change' }],//超级管理员证件有效期结束时间	
                    business_authorization_letterImg: [{ validator: checkBusiness_authorization_letterImg, trigger: 'change' }],//业务办理授权函	
                    mobile_phone: [{required: true, message:'请输入超级管理员手机', trigger: 'blur'}],//超级管理员手机	
                    contact_email: [{ validator: checkContact_email, trigger: 'change' }],//超级管理员邮箱	
                },
                contact_typeDic: [],//
                contact_id_doc_typeDic: [],//
                business_timeType: false
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
                this.$nextTick(()=>{
                    this.$refs['contact_id_doc_copyImg'].initFileList()
                    this.$refs['contact_id_doc_copy_backImg'].initFileList()
                    this.$refs['business_authorization_letterImg'].initFileList()
                })
            },
        },
        props: ['organization_type'],
        created () {
            this.$getDic('contact_type','select').then(res=>{ this.contact_typeDic = res; })
            this.$getDic('id_doc_type','select').then(res=>{ this.contact_id_doc_typeDic = res; })
        },
        methods:{
            contact_typeChange () {

            },
            contact_id_doc_typeChange () {

            },
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$message.success('操作成功')
                        if(this.business_timeType){
                            this.form.contact_id_doc_period_end = '长期'
                        }
                        let reqData = {
                            ...this.form
                        }
                        this.$emit('change',reqData);
                        this.dialogVisible = false
                    }
                })
                
            },
            business_timeTypeChange () {
                this.form.contact_id_doc_period_end = ''
            },
            windowOpen (url) {
                window.open(url)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>