<template>
    <div>
        <el-dialog
            title="店铺信息"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                    <el-form-item label="店铺名称" prop="store_name">
                        <el-input maxlength="100" v-model="form.store_name" placeholder="请输入店铺名称" style="width: 400px"></el-input>
                    </el-form-item>
                    <el-form-item label="店铺链接" prop="store_url">
                        <el-input maxlength="100" v-model="form.store_url" placeholder="请输入店铺链接" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.店铺二维码 or 店铺链接二选一必填。</span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="store_qr_codeImg" label="店铺二维码">
                        <le-upload-img-wx notnull ref="store_qr_codeImg" label="" :limit="1" :imgList.sync="form.store_qr_codeImg" :mediaList.sync="form.store_qr_code"></le-upload-img-wx>
                        <div class="tips">
                            <span>1.店铺二维码 or 店铺链接二选一必填。</span>
                            <span>2.若为电商小程序，可上传店铺页面的小程序二维码。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="小程序AppID" prop="mini_program_sub_appid">
                        <el-input maxlength="100" v-model="form.mini_program_sub_appid" placeholder="请输入小程序AppID" style="width: 400px"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
import LeUploadImgWx from '../../components/form/le-upload-img-wx.vue';
    export default {
        components: {
            LeUploadImgWx
        },
        data() {
            var checkStore_url = (rule, value, callback) => {
                if(!this.form.store_url && !this.form.store_qr_codeImg) {
                    callback(new Error('请输入店铺链接或上传店铺二维码'));
                }else{
                    callback();
                }
            };
            return {
                dialogVisible: false,
                form: {
                    store_name: '',//店铺名称
                    store_url: '',//店铺链接	
                    store_qr_codeImg: [],//店铺二维码	
                    store_qr_code: [],//店铺二维码	 MediaID
                    mini_program_sub_appid: '',//小程序AppID
                },
                rules: {
                    store_name: [{required: true, message:'请输入店铺名称', trigger: 'blur'}],//店铺名称
                    store_url: [{ validator: checkStore_url, trigger: 'change' }],//店铺链接	
                    store_qr_codeImg: [{ validator: checkStore_url, trigger: 'change' }],//店铺二维码	
                },
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
                this.$nextTick(()=>{
                    this.$refs['store_qr_codeImg'].initFileList()
                })
            },
        },
        created () {
            // this.$getDic('purchasePayType').then(res=>{ this.stateDic = res; })
        },
        methods:{
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$message.success('操作成功')
                        let reqData = {
                            ...this.form
                        }
                        this.$emit('change',reqData);
                        this.dialogVisible = false
                    }
                })
                
            },
            windowOpen (url) {
                window.open(url)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>