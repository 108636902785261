<template>
    <div>
        <el-dialog
            title="营业执照/登记证书信息"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                    <el-form-item prop="cert_type" label="证书类型">
                        <el-select v-model="form.cert_type" clearable :disabled="organization_type == 2" @change="cert_typeChange" style="width: 400px">
                            <el-option v-for="item in cert_typeDic"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="tips">
                            <span>1.主体为“政府机关/事业单位/社会组织”时，请上传登记证书类型。</span>
                            <span>2.主体为“个体工商户/企业”时，不填。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="营业执照扫描件" prop="business_license_copyImg">
                        <le-upload-img-wx notnull ref="business_license_copyImg" label="" :limit="1" :imgList.sync="form.business_license_copyImg" :mediaList.sync="form.business_license_copy"></le-upload-img-wx>
                        <div class="tips">
                            <span>主体为“个体工商户/企业”时，请上传营业执照的证件图片；主体为“政府机关/事业单位/社会组织”时，请上传登记证书的证件图片。</span>
                            <span>1.照片应正面拍摄、清晰、四角完整、无反光或遮挡；不得翻拍、截图、镜像、PS。</span>
                            <span>2.上传彩色照片、彩色扫描件，复印件需加盖公章鲜章。</span>
                            <span>3.水印仅限于微信支付业务相关。</span>
                            <span>
                                4.指引与示例可参考
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/220307J3iYbE220307vmuY3E.html')">【指引文档】</span>
                            </span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="business_license_number" label="营业执照注册号">
                        <el-input v-model="form.business_license_number" placeholder="请输入营业执照注册号" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.主体为“个体工商户”时，请填写营业执照上的注册号/统一社会信用代码，格式需满足以下任一条件：</span>
                            <span class="a-plr-18">-15位数字</span>
                            <span class="a-plr-18">-18位阿拉伯数字或大写英文字母（不得包含英文字母I/O/Z/S/V），并且以9开头</span>
                            <span>2.主体为“企业”时，请填写营业执照上的注册号/统一社会信用代码，格式如下：</span>
                            <span class="a-plr-18">-18位阿拉伯数字或大写英文字母（不得包含英文字母I/O/Z/S/V），并且以9开头</span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="merchant_name" label="商户名称">
                        <el-input maxlength="128" v-model="form.merchant_name" placeholder="请输入商户名称" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.前后不能有空格、制表符、换行符；不能仅含数字、特殊字符；仅能填写数字、英文字母、汉字及特殊字符。</span>
                            <span>2.个体户证件为以下情况时，按照个体户XXX命名（XXX是营业执照经营人姓名）：营业执照登记名称为空、仅含数字、仅含特殊字符、“无”、“无字号”</span>
                            <span>3.个体户不能使用“企业”“公司”或“农民专业合作社”结尾</span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="legal_person" label="经营者/法定代表人姓名">
                        <el-input maxlength="100" v-model="form.legal_person" placeholder="请输入经营者/法定代表人姓名" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.前后不能有空格、制表符、换行符；不能仅含数字、特殊字符；仅能填写数字、英文字母、汉字及特殊字符。</span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="company_address" label="注册地址">
                        <el-input maxlength="100" v-model="form.company_address" placeholder="请输入注册地址" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.主体为“政府机关/事业单位/社会组织”时必填，请填写登记证书的注册地址。</span>
                            <span>2.主体为“企业/个体户”时建议填写营业执照的注册地址，若该字段未填写，系统将会查询国家工商信息填入。需注意若工商信息查询不到，则会被审核驳回。</span>
                            <span>3.前后不能有空格、制表符、换行符；不能仅含数字、特殊字符；仅能填写数字、英文字母、汉字及特殊字符。</span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="business_time" label="营业期限">
                        <el-date-picker
                            v-model="form.business_time[0]"
                            type="date"
                            :value-format="'yyyy-MM-dd'"
                            :picker-options="pickerOptions0"
                            placeholder="选择开始日期">
                        </el-date-picker>
                        <span class="a-plr-18">至</span>
                        <el-date-picker
                            :disabled="business_timeType"
                            v-model="form.business_time[1]"
                            type="date"
                            :value-format="'yyyy-MM-dd'"
                            :picker-options="pickerOptions1"
                            placeholder="选择结束日期">
                        </el-date-picker>
                        <el-checkbox class="a-mlr-12" v-model="business_timeType" @change='business_timeTypeChange'>长期</el-checkbox>
                        <div class="tips">
                            <span>1.主体为“政府机关/事业单位/社会组织”时必填，请填写证件有效期。</span>
                            <span>2.主体为“企业/个体户”时建议填写，若该字段未填写，系统将会查询国家工商信息填入。需注意若工商信息查询不到，则会被审核驳回。</span>
                            <span>3.若证件有效期为长期，请勾选：长期。</span>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
    import LeUploadImgWx from '../../components/form/le-upload-img-wx.vue';
    export default {
        components: {
            LeUploadImgWx
        },
        data() {
            return {
                dialogVisible: false,
                form: {
                    cert_type: '',//证书类型
                    business_license_copyImg: [],//营业执照扫描件图片
                    business_license_copy: [],//营业执照扫描件MediaID
                    business_license_number: '',//营业执照注册号	
                    merchant_name: '',//商户名称
                    legal_person: '',//经营者/法定代表人姓名	
                    company_address: '',//注册地址	 
                    business_time: ['',''],//营业期限	
                },
                rules: {
                    business_license_copyImg: [{required: true, message:'请上传营业执照扫描件', trigger: 'change'}],//营业执照扫描件图片
                    business_license_number: [{required: true, message:'请填写营业执照注册号', trigger: 'blur'}],//营业执照注册号	
                    merchant_name: [{required: true, message:'请填写商户名称', trigger: 'blur'}],//商户名称
                    legal_person: [{required: true, message:'请填写经营者/法定代表人姓名', trigger: 'blur'}],//经营者/法定代表人姓名	
                },
                business_timeType: false,
                cert_typeDic: [],//证书类型字典
                pickerOptions0: { 
                    disabledDate: (time)=> {
                        if (this.form.business_time[1] != "") {
                            return time.getTime() <  new Date(this.form.business_time[1]).getTime() || time.getTime() > Date.now();
                        }
                        return time.getTime() > Date.now()
                    }
                },
                pickerOptions1: {
                    disabledDate: (time) => {
                        return time.getTime() < new Date(this.form.business_time[0]).getTime()
                    }
                },
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
                this.$nextTick(()=>{
                    this.$refs['business_license_copyImg'].initFileList()
                })
            },
        },
        created () {
            this.$getDic('cert_type','select').then(res=>{ this.cert_typeDic = res; })
        },
        props: ['organization_type'],
        methods:{
            // 证书类型改变
            cert_typeChange () {

            },
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$message.success('操作成功')
                        if(this.business_timeType){
                            this.form.business_time = [this.form.business_time[0], '长期']
                        }
                        let reqData = {
                            ...this.form
                        }
                        this.$emit('change',reqData);
                        this.dialogVisible = false
                    }
                })
                
            },
            business_timeTypeChange () {
                this.form.business_time[1] = ''
            },
            windowOpen (url) {
                window.open(url)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>