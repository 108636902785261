<template>
    <div>
        <el-dialog
            title="经营者/法人身份证信息"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                    <el-form-item prop="id_card_copyImg" label="身份证人像面照片">
                        <le-upload-img-wx notnull ref="id_card_copyImg" label="" :limit="1" :imgList.sync="form.id_card_copyImg" :mediaList.sync="form.id_card_copy"></le-upload-img-wx>
                        <div class="tips">
                            <span>1.请上传个体户经营者/法人的身份证人像面照片。</span>
                            <span>2.照片应正面拍摄、清晰、四角完整、无反光或遮挡；不得翻拍、截图、镜像、PS。</span>
                            <span>
                                3.请上传彩色照片or彩色扫描件，复印件需加盖公章鲜章，可添加“微信支付”相关水印（如微信支付认证），见
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/230615MjMBRf230615NNn2yY.html')">【指引文档】</span>
                            </span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="id_card_nationalImg" label="身份证国徽面照片">
                        <le-upload-img-wx notnull ref="id_card_nationalImg" label="" :limit="1" :imgList.sync="form.id_card_nationalImg" :mediaList.sync="form.id_card_national"></le-upload-img-wx>
                        <div class="tips">
                            <span>1.请上传个体户经营者/法定代表人的身份证国徽面照片。</span>
                            <span>2.照片应正面拍摄、清晰、四角完整、无反光或遮挡；不得翻拍、截图、镜像、PS。</span>
                            <span>
                                3.请上传彩色照片or彩色扫描件，复印件需加盖公章鲜章，可添加“微信支付”相关水印（如微信支付认证），见
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/230615MjMBRf230615NNn2yY.html')">【指引文档】</span>
                            </span>
                        </div>
                    </el-form-item>
                    <el-form-item label="身份证姓名" prop="id_card_name">
                        <el-input maxlength="100" v-model="form.id_card_name" placeholder="请输入身份证姓名" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.请填写个体户经营者/法定代表人对应身份证的姓名</span>
                            <span>2.前后不能有空格、制表符、换行符，不能仅含数字、特殊字符，仅能填写数字、英文字母、汉字及特殊字符。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="身份证号码" prop="id_card_number">
                        <el-input maxlength="100" v-model="form.id_card_number" placeholder="请输入身份证号码" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.请填写经营者/法定代表人对应身份证的号码</span>
                            <span>2.前后不能有空格、制表符、换行符，不能仅含数字、特殊字符，仅能填写数字、英文字母、汉字及特殊字符。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="身份证居住地址" prop="id_card_address">
                        <el-input maxlength="128" v-model="form.id_card_address" placeholder="请输入身份证居住地址" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.主体类型为企业时，需要填写。其他主体类型，无需上传。</span>
                            <span>2.请按照身份证住址填写。</span>
                            <span>3.前后不能有空格、制表符、换行符，不能仅含数字、特殊字符，仅能填写数字、英文字母、汉字及特殊字符。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="身份证开始时间" prop="id_card_valid_time_begin">
                        <el-date-picker
                            v-model="form.id_card_valid_time_begin"
                            type="date"
                            :value-format="'yyyy-MM-dd'"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="身份证结束时间" prop="id_card_valid_time">
                        <el-date-picker
                            :disabled="business_timeType"
                            v-model="form.id_card_valid_time"
                            type="date"
                            :value-format="'yyyy-MM-dd'"
                            placeholder="选择日期">
                        </el-date-picker>
                        <el-checkbox class="a-mlr-12" v-model="business_timeType" @change='business_timeTypeChange'>长期</el-checkbox>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
import LeUploadImgWx from '../../components/form/le-upload-img-wx.vue';
    export default {
        components: {
            LeUploadImgWx
        },
        data() {
            var checkId_card_address = (rule, value, callback) => {
                if (this.organization_type == '2') {
                    if(!this.form.id_card_address) {
                        callback(new Error('请填写身份证居住地址'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkId_card_valid_time = (rule, value, callback) => {
                if (this.business_timeType) {
                    callback();
                } else {
                    if(!this.form.id_card_valid_time) {
                        callback(new Error('请选择身份证结束时间'));
                    }else{
                        callback();
                    }
                }
            };
            
            return {
                dialogVisible: false,
                form: {
                    id_card_copyImg: [],//身份证人像面照片	
                    id_card_copy: [],//身份证人像面照片	MediaID
                    id_card_nationalImg: [],//身份证国徽面照片
                    id_card_national: [],//身份证国徽面照片 MediaID
                    id_card_name: '',//身份证姓名
                    id_card_number: '',//身份证号码
                    id_card_address: '',//身份证居住地址
                    id_card_valid_time_begin: '',//身份证开始时间
                    id_card_valid_time: '',//身份证结束时间	
                },
                rules: {
                    id_card_copyImg: [{required: true, message:'请上传身份证人像面照片', trigger: 'change'}],//身份证人像面照片	
                    id_card_nationalImg: [{required: true, message:'请上传身份证国徽面照片', trigger: 'change'}],//身份证国徽面照片
                    id_card_name: [{required: true, message:'请填写身份证姓名', trigger: 'blur'}],//身份证姓名
                    id_card_number: [{required: true, message:'请填写身份证号码', trigger: 'blur'}],//身份证号码
                    id_card_address: [{ validator: checkId_card_address, trigger: 'blur' }],//身份证居住地址
                    id_card_valid_time_begin: [{required: true, message:'请选择身份证开始时间', trigger: 'change'}],//身份证开始时间
                    id_card_valid_time: [{ validator: checkId_card_valid_time, trigger: 'change'}],//身份证结束时间	
                },
                business_timeType: false
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
                this.$nextTick(()=>{
                    this.$refs['id_card_copyImg'].initFileList()
                    this.$refs['id_card_nationalImg'].initFileList()
                })
            },
        },
        created () {
            // this.$getDic('purchasePayType').then(res=>{ this.stateDic = res; })
        },
        props: ['organization_type'],
        methods:{
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$message.success('操作成功')
                        if(this.business_timeType){
                            this.form.id_card_valid_time = '长期'
                        }
                        let reqData = {
                            ...this.form
                        }
                        this.$emit('change',reqData);
                        this.dialogVisible = false
                    }
                })
                
            },
            business_timeTypeChange () {
                this.form.id_card_valid_time = ''
            },
            windowOpen (url) {
                window.open(url)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>