<template>
    <div>
        <el-dialog
            title="金融机构许可证信息"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                    <el-form-item prop="finance_type" label="证书类型">
                        <el-select v-model="form.finance_type" clearable @change="finance_typeChange">
                            <el-option v-for="item in finance_typeDic"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="tips">
                            <span>
                                1.金融机构类型需与营业执照/登记证书上一致，可参考选择
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/220215IrMRZ3220215n6buiU.html')">金融机构指引</span>
                            </span>
                        </div>
                    </el-form-item>
                    <el-form-item label="金融机构许可证图片" prop="finance_license_picsImg">
                        <le-upload-img-wx notnull ref="finance_license_picsImg" label="" :limit="5" :imgList.sync="form.finance_license_picsImg" :mediaList.sync="form.finance_license_pics"></le-upload-img-wx>
                        <div class="tips">
                            <span>1.照片应正面拍摄、清晰、四角完整、无反光或遮挡；不得翻拍、截图、镜像、PS。</span>
                            <span>2.上传彩色照片、彩色扫描件，复印件需加盖公章鲜章。</span>
                            <span>3.水印仅限于微信支付业务相关。</span>
                            <span>
                                4.根据所属金融机构类型的许可证要求提供，详情查看
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/220215IrMRZ3220215n6buiU.html')">金融机构指引</span>
                            </span>
                            <span>5.请提供为“申请商家主体”所属的许可证，可授权使用总公司/分公司的特殊资质。</span>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
import LeUploadImgWx from '../../components/form/le-upload-img-wx.vue';
    export default {
        components: {
            LeUploadImgWx
        },
        data() {
            return {
                dialogVisible: false,
                form: {
                    finance_type: '',//金融机构类型	
                    finance_license_picsImg: [],//金融机构许可证图片	图片
                    finance_license_pics: [],//金融机构许可证图片	MediaID
                },
                rules: {
                    finance_type: [{required: true, message:'请选择金融机构类型', trigger: 'change'}],//金融机构类型	
                    finance_license_picsImg: [{ type: 'array', required: true, message: '请至少上传一张金融机构许可证图片', trigger: 'change' }],//金融机构许可证图片	图片
                },
                finance_typeDic: [],//金融机构类型字典
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
                this.$nextTick(()=>{
                    this.$refs['finance_license_picsImg'].initFileList()
                })
            },
        },
        created () {
            this.$getDic('finance_type','select').then(res=>{ this.finance_typeDic = res; })
        },
        methods:{
            // 金融机构类型改变
            finance_typeChange () {

            },
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$message.success('操作成功')
                        let reqData = {
                            ...this.form
                        }
                        this.$emit('change',reqData);
                        this.dialogVisible = false
                    }
                })
                
            },
            windowOpen (url) {
                window.open(url)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>