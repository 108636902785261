<template>
    <div>
        <el-dialog
            title="添加最终受益人"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                    <el-form-item prop="ubo_id_doc_type" label="证件类型">
                        <el-select v-model="form.ubo_id_doc_type" clearable @change="ubo_id_doc_typeChange">
                            <el-option v-for="item in ubo_id_doc_typeDic"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="ubo_id_doc_copyImg" label="证件正面照片">
                        <le-upload-img-wx notnull ref="ubo_id_doc_copyImg" label="" :limit="1" :imgList.sync="form.ubo_id_doc_copyImg" :mediaList.sync="form.ubo_id_doc_copy"></le-upload-img-wx>
                        <div class="tips">
                            <span>1.若证件类型为身份证，请上传人像面照片。</span>
                            <span>2.照片应正面拍摄、清晰、四角完整、无反光或遮挡；不得翻拍、截图、镜像、PS。</span>
                            <span>
                                3.请上传彩色照片or彩色扫描件，复印件需加盖公章鲜章，可添加“微信支付”相关水印（如微信支付认证），见
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/230615MjMBRf230615NNn2yY.html')">【指引文档】</span>
                            </span>
                        </div>
                    </el-form-item>
                    <el-form-item prop="ubo_id_doc_copy_backImg" label="证件反面照片">
                        <le-upload-img-wx notnull ref="ubo_id_doc_copy_backImg" label="" :limit="1" :imgList.sync="form.ubo_id_doc_copy_backImg" :mediaList.sync="form.ubo_id_doc_copy_back"></le-upload-img-wx>
                        <div class="tips">
                            <span>1.若证件类型为身份证，请上传国徽面照片。</span>
                            <span>2.照片应正面拍摄、清晰、四角完整、无反光或遮挡；不得翻拍、截图、镜像、PS。</span>
                            <span>
                                3.请上传彩色照片or彩色扫描件，复印件需加盖公章鲜章，可添加“微信支付”相关水印（如微信支付认证），见
                                <span class="a-c-blue font-point" @click="windowOpen('https://kf.qq.com/faq/230615MjMBRf230615NNn2yY.html')">【指引文档】</span>
                            </span>
                        </div>
                    </el-form-item>
                    <el-form-item label="证件姓名" prop="ubo_id_doc_name">
                        <el-input maxlength="100" v-model="form.ubo_id_doc_name" placeholder="请输入证件姓名" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.前后不能有空格、制表符、换行符，不能仅含数字、特殊字符，仅能填写数字、英文字母、汉字及特殊字符。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="证件号码" prop="ubo_id_doc_number">
                        <el-input maxlength="100" v-model="form.ubo_id_doc_number" placeholder="请输入证件号码" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>1.前后不能有空格、制表符、换行符，不能仅含数字、特殊字符，仅能填写数字、英文字母、汉字及特殊字符。</span>
                            <span>2.可传身份证、来往内地通行证、来往大陆通行证、护照等证件号码，号码规范如下：</span>
                            <span class="a-plr-18">身份证（限中国大陆居民)：17位数字+1位数字|X</span>
                            <span class="a-plr-18">护照（限境外人士）：4-15位 数字|字母|连字符</span>
                            <span class="a-plr-18">中国香港居民--来往内地通行证：H/h开头+8或10位数字/字母</span>
                            <span class="a-plr-18">中国澳门居民--来往内地通行证：M/m开头+8或10位数字/字母</span>
                            <span class="a-plr-18">中国台湾居民--来往大陆通行证：8位数字或10位数字</span>
                            <span class="a-plr-18">外国人居留证：15位 数字|字母</span>
                            <span class="a-plr-18">港澳居住证/台湾居住证：17位数字+1位数字|X</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="证件居住地址" prop="ubo_id_doc_address">
                        <el-input maxlength="128" v-model="form.ubo_id_doc_address" placeholder="请输入证件居住地址" style="width: 400px"></el-input>
                        <div class="tips">
                            <span>2.请按照证件居住地址填写。</span>
                            <span>3.前后不能有空格、制表符、换行符，不能仅含数字、特殊字符，仅能填写数字、英文字母、汉字及特殊字符。</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="证件有效期开始时间" prop="ubo_id_doc_period_begin">
                        <el-date-picker
                            v-model="form.ubo_id_doc_period_begin"
                            type="date"
                            :value-format="'yyyy-MM-dd'"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="证件有效期结束时间" prop="ubo_id_doc_period_end">
                        <el-date-picker
                            :disabled="business_timeType"
                            v-model="form.ubo_id_doc_period_end"
                            type="date"
                            :value-format="'yyyy-MM-dd'"
                            placeholder="选择日期">
                        </el-date-picker>
                        <el-checkbox class="a-mlr-12" v-model="business_timeType" @change='business_timeTypeChange'>长期</el-checkbox>
                    </el-form-item>
                </el-form>
            </div>
            
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
import LeUploadImgWx from '../../components/form/le-upload-img-wx.vue';
    export default {
        components: {
            LeUploadImgWx
        },
        data() {
            var checkUbo_id_doc_copy_backImg = (rule, value, callback) => {
                if (this.form.ubo_id_doc_type != 'IDENTIFICATION_TYPE_OVERSEA_PASSPORT') {
                    if(!this.form.ubo_id_doc_copy_backImg) {
                        callback(new Error('请上传证件反面照片'));
                    }else{
                        callback();
                    }
                } else {
                    callback();
                }
            };
            var checkUbo_id_doc_period_end = (rule, value, callback) => {
                if (this.business_timeType) {
                    callback();
                } else {
                    if(!this.form.ubo_id_doc_period_end) {
                        callback(new Error('请选择证件有效期结束时间'));
                    }else{
                        callback();
                    }
                }
            };
            return {
                dialogVisible: false,
                form: {
                    ubo_id_doc_type: '',//证件类型
                    ubo_id_doc_copyImg: [],//证件正面照片		
                    ubo_id_doc_copy: [],//证件正面照片	MediaID
                    ubo_id_doc_copy_backImg: [],//证件反面照片
                    ubo_id_doc_copy_back: [],//证件反面照片 MediaID
                    ubo_id_doc_name: '',//证件姓名	
                    ubo_id_doc_number: '',//证件号码	
                    ubo_id_doc_address: '',//证件居住地址	
                    ubo_id_doc_period_begin: '',//证件有效期开始时间
                    ubo_id_doc_period_end: '',//证件有效期结束时间		
                },
                rules: {
                    ubo_id_doc_type: [{required: true, message:'请选择证件类型', trigger: 'change'}],//证件类型
                    ubo_id_doc_copyImg: [{required: true, message:'请上传证件正面照片', trigger: 'change'}],//证件正面照片		
                    ubo_id_doc_copy_backImg: [{ validator: checkUbo_id_doc_copy_backImg, trigger: 'change' }],//证件反面照片
                    ubo_id_doc_name: [{required: true, message:'请输入证件姓名', trigger: 'blur'}],//证件姓名	
                    ubo_id_doc_number: [{required: true, message:'请输入证件号码', trigger: 'blur'}],//证件号码	
                    ubo_id_doc_address: [{required: true, message:'请输入证件居住地址', trigger: 'blur'}],//证件居住地址	
                    ubo_id_doc_period_begin: [{required: true, message:'请选择证件有效期开始时间', trigger: 'change'}],//证件有效期开始时间
                    ubo_id_doc_period_end: [{ validator: checkUbo_id_doc_period_end, trigger: 'change'}],//证件有效期结束时间	
                },
                ubo_id_doc_typeDic: [],//证件类型字典
                business_timeType: false
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                    this.$refs['ubo_id_doc_copy_backImg'].fileList = []
                    this.$refs['ubo_id_doc_copyImg'].fileList = []
                }
            },
        },
        created () {
            this.$getDic('id_doc_type','select').then(res=>{ this.ubo_id_doc_typeDic = res; })
        },
        methods:{
            //证件类型改变
            ubo_id_doc_typeChange () {

            },
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$message.success('操作成功')
                        if(this.business_timeType){
                            this.form.ubo_id_doc_period_end = '长期'
                        }
                        let reqData = {
                            ...this.form
                        }
                        this.$emit('change',reqData);
                        this.dialogVisible = false
                    }
                })
                
            },
            business_timeTypeChange () {
                this.form.ubo_id_doc_period_end = ''
            },
            windowOpen (url) {
                window.open(url)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>